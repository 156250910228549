import React from 'react';
import logo from './logo.gif';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hallo 
        </p>
        <ul>
          <li><a href="https://github.com/jesseblom" className="App-link" target="_blank" rel="noopener noreferrer">Github</a></li>
          <li><a href="https://eu-west-1.console.aws.amazon.com" className="App-link" target="_blank" rel="noopener noreferrer">AWS</a></li>
          {/* Add more links as needed */}
        </ul>
      </header>
    </div>
  );
}

export default App;